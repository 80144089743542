import React from 'react'

import './OmniceptHeartRate.scss'

const OvationOverShoulder = 'https://d1syj4d8txnu77.cloudfront.net/Omnicept/PersonWearingReverbG2.jpg'

export default class OmniceptHeartRate extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="omnicept-heart-rate">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-6">
              <h2>Feel your nerves fade away</h2>
              <h4>
                Review the changes in your heart rate over a series of speeches and in front of different sized
                audiences.
              </h4>
            </div>
            <div className="col-md-6">
              <img src={OvationOverShoulder} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
