import React from 'react'

import OmniceptVideosStacked from '../OmniceptVideosStacked/OmniceptVideosStacked'
import './OmniceptDataPoints.scss'

const ViveDataPointsVideo1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.mp4'
const ViveDataPointsPoster1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.jpg'
const ViveDataPointsVideo2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_ET_Bottom.mp4'
const ViveDataPointsPoster2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.jpg'

const OmniceptDataPoints = () => {
  return (
    <div className="omnicept-data-points">
      <div className="wrapper omnicept-wrapper">
        <div className="omnicept-data-points-body">
          <div className="row">
            <div className="col-md-6 omnicept-data-section order-md-2">
              <h2>Pinpoint Accuracy</h2>
              <h4>
                Most VR headsets only provide information about where your head is pointed. Omnicept takes that to the
                next level by determining the <b>exact location your eyes are looking</b>, leading to more accurate
                analytics and better insights.
                <br />
                <br />
                In these clips, the speaker looks left and right with their head, but only eye tracking is able to
                reveal how extensively he scanned the crowd.
              </h4>
            </div>
            <div className="col-md-6 omnicept-data-section omnicept-data-section-img order-md-1">
              <OmniceptVideosStacked
                video1Url={ViveDataPointsVideo1}
                video1Poster={ViveDataPointsPoster1}
                video2Url={ViveDataPointsVideo2}
                video2Poster={ViveDataPointsPoster2}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OmniceptDataPoints
