import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './OmniceptCognitiveLoad.scss'

const SharingImage = 'https://d1syj4d8txnu77.cloudfront.net/Omnicept/CogLoadGraph.jpg'

export default class OmniceptCognitiveLoad extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="omnicept-cognitive-load">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-6 order-md-2">
              <h2>Know when you are in the training zone</h2>
              <h4>
                Sensors in the headset can determine your cognitive load in real-time, capturing the brainpower needed
                to remember and properly deliver your speech.
              </h4>
              <h4>
                <a
                  className="gtm-outbound-link-omnicept-cognitive-load"
                  href="https://developers.hp.com/omnicept/blog/hp-labs-cognitive-load"
                  target="_blank"
                >
                  Learn more about cognitive load. <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </h4>
            </div>

            <div className="col-md-6 order-md-1">
              <img src={SharingImage} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
