import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import './OmniceptFooter.scss'

function goToContactUs() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'gtm-site-link-contact-omnicept-page'
  })
  navigate('/contact')
}

const OmniceptFooter = () => {
  return (
    <div className="omnicept-footer">
      <div className="wrapper">
        <h2>Interested in Omnicept for your organization?</h2>
        <div className="omnicept-product-btns">
          <a onClick={goToContactUs} className="omnicept-product-btn">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default OmniceptFooter
