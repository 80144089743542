import React from 'react'

import OmniceptEyeTrackingVideo from '../OmniceptEyeTrackingVideo/OmniceptEyeTrackingVideo'
import './OmniceptHeatmap.scss'

const OmniceptHeatmap = () => {
  return (
    <div className="omnicept-heatmap">
      <div className="wrapper">
        <div className="row omnicept-heatmap-body">
          <div className="col-md-6 omnicept-heatmap-text-section">
            <h2>Previously Impossible Training Tools</h2>
            <div>
              Master the art of keeping your attention on the audience, instead of your notes.
              <br />
              <br />
              Ovation gently reminds you to shift your gaze when it lingers too long. Made possible by eye tracking so
              accurate that it knows what line you’re reading on a teleprompter 15 feet away.
            </div>
          </div>
          <div className="col-md-6">
            <OmniceptEyeTrackingVideo showLineDescription={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OmniceptHeatmap
