import React from 'react'
import { Link } from 'gatsby'

import './OmniceptProductHero.scss'

const OmniceptProductHero = () => {
  return (
    <div className="omnicept-product-header">
      <div className="wrapper">
        <h1>Ovation & Omnicept</h1>
        <h2>
          Next level training.
          <br />
          Uncompromised realism.
        </h2>
        <div className="omnicept-product-btns">
          <Link to="/contact" className="gtm-outbound-link-hp-store-top-buy-omnicept omnicept-product-btn">
            Contact Us to Buy
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OmniceptProductHero
