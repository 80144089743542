import React from 'react'

import './OmniceptEyeTrackingVideo.scss'

const HPOmnicept_Logo = 'https://d1syj4d8txnu77.cloudfront.net/Omnicept/HPLogo.png'
const SpeechToolVideo = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/SpeechTool.mp4'
const SpeechToolPoster = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/SpeechTool.jpg'

const OmniceptEyeTrackingVideo = (props) => {
  return (
    <div className="omnicept-heatmap-video-container">
      <div style={{ display: props.showLineDescription ? 'block' : 'none' }}>
        <h2 className="video-title-line">
          <span style={{ color: 'red' }}>Red Line</span>: No Eye Tracking
        </h2>
        <h2 className="video-title-line" style={{ marginBottom: '10px' }}>
          <div className="one">
            <span style={{ color: 'lightgreen' }}>Green line</span>:&nbsp;
          </div>
          <div className="two">
            <div className="omnicept-image-wrapper">
              <img src={HPOmnicept_Logo} />
              <span>Omnicept</span>
            </div>
          </div>
        </h2>
      </div>

      <video
        className="video" // d-none d-md-block
        loop
        autoPlay="autoplay"
        poster={SpeechToolPoster}
        muted
        playsInline

        // onCanPlay={() => {
        //   this.setState({
        //     videoLoading: false,
        //   })
        // }}
      >
        <source src={SpeechToolVideo} type="video/mp4" />
        <img src={SpeechToolPoster} title="Your browser does not support the <video> tag" />
      </video>
    </div>
  )
}

export default OmniceptEyeTrackingVideo
