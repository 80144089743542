import React from 'react'
import './OmniceptHero.scss'

const heroImg = 'https://d1syj4d8txnu77.cloudfront.net/Omnicept/Omnicept_Banner_Desktop.jpg'
const mobileHeroImg = 'https://d1syj4d8txnu77.cloudfront.net/Omnicept/Omnicept_Banner_Mobile.jpg'

export default class OmniceptHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoOpen: false,
      width: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  toggleVideo() {
    this.setState({
      videoOpen: !this.state.videoOpen
    })
  }

  render() {
    const dynamicHeroImg = this.state.width > 700 ? heroImg : mobileHeroImg
    const heroStyle = {
      backgroundImage: `url(${dynamicHeroImg})`
    }
    return (
      <div className="omnicept-hero">
        <img src={dynamicHeroImg} />
      </div>
    )
  }
}
