import React from 'react'

import './OmniceptIntro.scss'

export default ({}) => (
  <div className="omnicept-intro">
    <div className="wrapper">
      <div className="row">
        <div className="col-lg-6">
          <h2>Omnicept is the most advanced VR headset for training available today</h2>
          <h4>
            Ovation’s available training features and analytics are expanded and enhanced by Omnicept’s eye tracking and
            biometrics.
          </h4>
        </div>
        <div className="col-lg-6">
          <div className="subhero-youtube-video-iframe">
            <iframe
              src="https://www.youtube.com/embed/u1sGCYWRDQU"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
)
