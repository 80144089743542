import React from 'react'
import Helmet from 'react-helmet'

import OmniceptHero from '../components/Omnicept/OmniceptHero/OmniceptHero'
import OmniceptProductHero from '../components/Omnicept/OmniceptProductHero/OmniceptProductHero'
import OmniceptIntro from '../components/Omnicept/OmniceptIntro/OmniceptIntro'
import OmniceptCognitiveLoad from '../components/Omnicept/OmniceptCognitiveLoad/OmniceptCognitiveLoad'
import OmniceptHeartRate from '../components/Omnicept/OmniceptHeartRate/OmniceptHeartRate'
import OmniceptDataPoints from '../components/Omnicept/OmniceptDataPoints/OmniceptDataPoints'
import OmniceptHeatmap from '../components/Omnicept/OmniceptHeatmap/OmniceptHeatmap'
import OmniceptAudience from '../components/Omnicept/OmniceptAudience/OmniceptAudience'
import OmniceptFooter from '../components/Omnicept/OmniceptFooter/OmniceptFooter'
import EmailList from '../components/EmailList/EmailList'

let scrolled25PercentYet, scrolled50PercentYet, scrolled75PercentYet, scrolled100PercentYet

class Omnicept extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    scrolled25PercentYet = false
    scrolled50PercentYet = false
    scrolled75PercentYet = false
    scrolled100PercentYet = false
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

    const scrolledPercentage = winScroll / height // 0 to 1

    window.dataLayer = window.dataLayer || []
    if (scrolledPercentage >= 0.25 && !scrolled25PercentYet) {
      console.log('gtm-behavior-omnicept-scroll-depth-25')
      window.dataLayer.push({
        event: 'gtm-behavior-omnicept-scroll-depth-25'
      })
      scrolled25PercentYet = true
    } else if (scrolledPercentage >= 0.5 && !scrolled50PercentYet) {
      console.log('gtm-behavior-omnicept-scroll-depth-50')
      window.dataLayer.push({
        event: 'gtm-behavior-omnicept-scroll-depth-50'
      })
      scrolled50PercentYet = true
    } else if (scrolledPercentage >= 0.75 && !scrolled75PercentYet) {
      console.log('gtm-behavior-omnicept-scroll-depth-75')
      window.dataLayer.push({
        event: 'gtm-behavior-omnicept-scroll-depth-75'
      })
      scrolled75PercentYet = true
    } else if (scrolledPercentage >= 1 && !scrolled100PercentYet) {
      console.log('gtm-behavior-omnicept-scroll-depth-100')
      window.dataLayer.push({
        event: 'gtm-behavior-omnicept-scroll-depth-100'
      })
      scrolled100PercentYet = true
    }
  }

  render() {
    return (
      <div id="omnicept" style={{ background: '#fff' }}>
        <Helmet title={'Ovation | HP Omnicept'}>
          <meta
            name="description"
            content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
          />
          <meta name="keywords" content="VR, Public, Speaking, Training" />
        </Helmet>
        <OmniceptHero />
        <OmniceptProductHero />
        <OmniceptIntro />
        <OmniceptCognitiveLoad />
        <OmniceptHeartRate />
        <OmniceptDataPoints />
        <OmniceptHeatmap />
        <OmniceptAudience />
        <OmniceptFooter />
        <EmailList gtmSubmitEventName="gtm-newsletter-add-contact-omnicept-page" backgroundColor="#fff" />
      </div>
    )
  }
}

export default Omnicept
